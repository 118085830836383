import * as React from 'react';
import { Link } from 'gatsby';

import { GatsbySeo } from 'gatsby-plugin-next-seo';

import PartnersPage from '../../../components/partners/partners';

import { ReactComponent as AtlassianMpPartnerSvg } from '../../../images/svg/partners/atlassian-mp-partner.svg';
import { ReactComponent as AtlassianLogoSvg } from './atlassian-logo.svg';

const pageTitle = 'Partnership with Atlassian';

const PartnersAtlassian = () => (
  <>
    <GatsbySeo
      language="en"
      title={pageTitle}
      _description=""
      openGraph={{
        url: 'https://www.dsapps.dev/partners/atlassian/',
      }}
    />

    <PartnersPage pageTitle={pageTitle}>
      <div className="container flex flex-col md:flex-row">
        <div className="flex flex-col mb-16 md:w-2/3 lg:pr-24 md:pr-16 md:items-start md:text-left md:mb-0">
          <div className="text-lg md:text-xl  lg:text-2xl leading-8">
            <p>
              We've partnered with Atlassian to elevate their software tools,
              making it easier for teams to collaborate, manage projects, and
              enhance software development efficiency.
            </p>
          </div>

          <div>
            <Link className="button-primary" to="/products/#/atlassian">
              View our Atlassian apps
            </Link>
          </div>

          <p className="mt-12">
            Atlassian creates software tools for teams to collaborate, manage
            projects, and develop software more efficiently.
          </p>

          <div>
            <Link
              target="_blank"
              className=" button-secondary"
              to="https://www.atlassian.com/try?utm_source=Partner&utm_campaign=dsapps.dev"
            >
              Try Atlassian software for free
            </Link>
          </div>

          <p className="text-xs text-gray-500 mt-8">
            <sup>*</sup>We are Atlassian Marketplace Partners
          </p>
        </div>
        <div className="items-center w-5/6 _md:mt-10 md:w-1/3">
          <Link
            target="_blank"
            to="https://www.atlassian.com/try?utm_source=Partner&utm_campaign=dsapps.dev"
          >
            <AtlassianLogoSvg />
          </Link>
        </div>
      </div>
    </PartnersPage>
  </>
);

export default PartnersAtlassian;
