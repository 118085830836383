import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../layout';
import Rule from '../rule';
import Section from '../section';

const PartnersPage = ({ pageTitle, children }) => (
  <Layout pageTitle={pageTitle}>
    <Section>
      <section className="body-font animate-underline-anchors">
        <div className="container flex flex-row py-4 mx-auto ">
          <div>
            <h2 className="mb-1 font-sans text-xs font-medium tracking-widest text-blue-500 title-font">
              <Link to="/partners/" className="uppercase animate-underline">
                Partners
              </Link>
            </h2>
            <Rule />
            <h1 className="my-4 text-2xl text-gray-900 dark:text-gray-100 sm:text-3xl title-font">
              {pageTitle}
            </h1>

            {children}
          </div>
        </div>
      </section>
    </Section>
  </Layout>
);
export default PartnersPage;
